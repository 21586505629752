import React from "react"

export const Footer = () => {
  return (
    <>
      <footer>
        <p>Copyright © GOSTO all rights reserved. Powered by GORKCODER.</p>
      </footer>
    </>
  )
}
